.dropScrollForm {
  overflow-y: scroll;
  height: 500px;
}

.custom-error {
  border: 1px solid #ff4d4f !important;
}

.custom-error-message {
  color: #ff4d4f;
  font-size: 12px;
}
.floating-section {
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 0px 30px 20px 0px;
  z-index: 1000 !important;
}

.without-padding {
  .ant-card-type-inner .ant-card-body {
    // padding-top: 10px;
    padding: 0 !important;
  }
  .ant-card-type-inner .ant-card-head-title {
    // padding-top: 10px;
    padding: 10px;
  }
}

.with-bold{
  font-weight: bold;
  font-size: 14px;
}
