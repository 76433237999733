.ant-upload-list {
  display: flex;
  flex-wrap: wrap;
  .ant-upload-list-picture-container {
    width: 500px;
    margin-right: 10px;
    margin-top: 10px;
    .ant-card-body {
      padding: 0px 20px;
    }
  }
}
