.main-card {
  margin-bottom: 10px;
}

.ant-card-head-title {
  font-size: 19px !important;
  font-weight: bold;
  text-transform: uppercase;
}

.heading {
  font-weight: bold;
  text-transform: capitalize;
  font-size: 18px;
  margin: 30px 0;
}

.subsection-title {
  font-weight: bold;
  font-size: 16px;
}

.subsection {
  margin-top: 20px;
  margin-bottom: 20px;
}

.options {
  margin: 10px 10px 10px 0;
}

.ant-steps-item-content {
  display: none !important;
}
.ant-steps-item-tail {
  // width: 35% !important;
  margin: 0 0 0 0px !important;
}
.ant-steps-item-icon {
  margin-left: 0px !important;
}
.subsectionCol {
  margin: 0 auto;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #2196f3 !important ;
}
.ant-radio-checked .ant-radio-inner:after {
  background-color: #2196f3;
}

.ant-radio:hover .ant-radio-inner {
  display: inline-block;
  border-color: #2196f3;
}

.ant-radio-wrapper {
  // padding: 45px;
}

.ant-checkbox-wrapper .ant-checkbox-wrapper-checked {
  border-color: #2196f3;
}

.ant-radio-wrapper .ant-radio-wrapper-checked {
  border-color: #2196f3;
}

.ant-radio-wrapper .ant-radio-wrapper-checked {
  border-color: blue !important;
}

.with-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.box-inner {
  .ant-collapse-extra {
    margin-top: -20px;
  }
}

.disorder-name {
  // background-color: cyan;
  display: block;
  text-align: center;
  // padding: 7px;
  // font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disorder-name .tooltiptext {
  visibility: hidden;
  display: inline-block;
  top: 70px;
  left: 0;
  width: 100%;
  white-space: pre-wrap;
  background-color: #2b292ac5;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 2px 0;
  position: absolute;
  z-index: 10;

  // visibility: hidden;
  // width:300px;
  // background-color: black;
  // color: #fff;
  // text-align: center;
  // border-radius: 6px;
  // padding:5px;
  // position: absolute;
  // z-index:1000;
  // border-radius:3px;
  // background:rgba(0,0,0,0.85);
  // font-size:12px;
  // font-weight: normal;
  // text-shadow:-1px 1px 0px rgba(0,0,0,0.2);
  // // line-height:150%;
  // color:#fff;
  //   // right: -90px;
  // left: -20px;
  // // top: 5px;
  // bottom: -5px;
  // margin-left: -60px;
  // display: inline-block;
  // white-space: pre-wrap;
}

.disorder-name:hover .tooltiptext {
  visibility: visible;
}

// .disorder-name.bottom .tooltiptext {
// 	top:20px;
// 	left:-10px;
// }

// .disorder-name.bottom .tooltiptext:before {
// 	top:-5px;
// 	left:10px;
// 	border-left:5px solid transparent;
// 	border-right:5px solid transparent;
// 	border-bottom:5px solid rgba(0,0,0,0.75);
// }

.sectionHeading {
  // background-color: cyan;
  display: block;
  text-align: center;
  padding: 7px;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sectiontooltiptext {
  visibility: hidden;
  display: inline-block;
  top: 155px;
  left: 0;
  width: 100%;
  white-space: pre-wrap;
  background-color: #2b292ac5;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 2px 0;
  position: absolute;
  z-index: 10;
}

.sectionHeading:hover .sectiontooltiptext {
  visibility: visible;
}

//arrow for tooltip
// .sectionHeading .sectiontooltiptext::after {
//   content: "";
//     position: absolute;
//     top: 100%;
//     left: 50%;
//     margin-left: -5px;
//     border-width: 5px;
//     border-style: solid;
//     border-color: #0da0d5 transparent transparent transparent;
// }

.radio_options {
  background-color: rgba(0, 0, 0, 0.4);
  // width: 150%;
  display: block;
  width: 200px;

  // margin-bottom: 0.4em;
  text-align: left;
  padding-left: 10px;

  border-radius: 7px;
  margin-bottom: 10px;
  justify-content: center;
}

.radio_options:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.radio_title {
  color: #fff;
  font-size: 20px;
  padding-left: 0.6em;
}

.checkbox_options {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  display: block;
  margin-bottom: 0.4em;
  text-align: left;
  padding-left: 0.8em;
  border-radius: 10px;
  // border: 3px solid #BADA55;
}

.checkbox_options:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.checkbox_title {
  // background-color: darkgreen;

  color: #fff;
  font-size: x-large;
  padding-left: 0.6em;
}

.disorder-upload {
  width: 300px !important;
  height: 200px !important;
}

// Checkbox.Group

.sketch-picker {
  position: absolute;
  z-index: 9999;
}
