@import "~antd/dist/antd.css";

// @font-face {
//   // font-family: 'Nunito';
//   font-family: 'Nunito Sans', sans-serif !important;
//   // src: local('Nunito'), url(../assets/fonts/Nunito/Nunito-Regular.ttf) format('ttf');
// }

// body{
//   font-family: 'Nunito Sans', sans-serif !important;
// }

// ::-webkit-scrollbar {
//   width: 10px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px grey; 
//   border-radius: 10px;
// }
 
// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: gray; 
//   border-radius: 10px;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: black; 
// }

// body {
//   @import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
//   font-family: 'Nunito';
// }

// OVERRIDES

// COLORS
.ant-btn-primary {
  background-color: #52C8FA !important;
  border-color: #52C8FA !important;
}

.ant-menu-item-selected a, .ant-menu-item-selected a:hover{
  color: #52C8FA !important;
}

.ant-menu-item-selected {
  color: #52C8FA !important;
}
.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #52C8FA !important;
}

.ant-layout{
  background-color: white !important;
}

.appLeftSider {
  background: white !important;
  color: black;
}


// OTHERS
.header-user-info {
  font-size: 12px;
  margin-right: 20px;
}
.base-header{
  border: none !important;
  background-color: white !important;
}
ul.ant-menu{
  padding-top: 10px !important;
}
.ant-menu-inline > .ant-menu-item{
  height: 50px !important;
  line-height: 50px !important;
}
.ant-layout-header{
  border-bottom: 1px solid #f0f0f0;
}
.ant-layout-content{
    // margin-top: -10px !important;
    // overflow-y: scroll;
    // height: 100vh;
}
.ant-modal{
  width: 1300px !important;
}
.ant-menu{
  height: 100%;
}
.table-header{
  background-color: white !important;
  border-bottom: 1px solid #f0f0f0;
  font-weight: 600;
}


// OVERRIDES END


//GENERIC
.text-right{
  text-align: right !important;
}
.m-0{
  margin: 0 !important;
}
.m-b-0{
  margin-bottom: 0 !important;
}
.m-b-10{
  margin-bottom: 10px !important;
}
.m-t-10{
  margin-top: 10px !important;
}
.p-l-10{
  padding-left: 10px !important;
}
.hidden{
  display: none !important;
}
.section-row .ant-col{
  padding-left: 12px !important;
  padding-right: 12px !important;
}

// FORMS
.ant-form-item-label > label{
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65);
}
.section-card{
  margin-bottom: 30px;
  .ant-card-head{
    background-color: #f0f0f0;
    .ant-card-head-title{
      text-transform: uppercase;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  // .ant-form-item-label{
  //   font-weight: bold;
  //   color: rgba(0, 0, 0, 0.65);
  // }
  .ant-form-item .conditional-child-input{
    margin-left: -24px;
  }
  .sub-heading{
    font-weight: bold;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.85);
  }
}
// FORMS END


// SHOW VIEW

.patient-name{
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}
.section-card-show{
  .section-field-row{
    width: 100%;
    .field-value{
      font-weight: bold;
    }
  }
  .ant-list-item{
    padding: 0px !important;
  }
}
// END SHOW VIEW

// LOGIN PAGE

.login-email input{
  height: 50px;
  line-height: 50px;
}
.login-password input{
  height: 40px;
  line-height: 40px;
}
.login-button{
  width: 100%;
  height: 50px;
  text-transform: uppercase;
  margin-top: 20px;
  font-size: 18px;
}

// END LOGIN PAGE



/* .App {
  text-align: center;
}


.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
// body{
//   margin-top:100px;
//   background-color: red;
// }

// .hidden{
//   color: red;
// }

.scanned-document-container{
  // position: fixed;
  // width: 50%;
  // height: 700px;
  height: 90vh;
  .media-show{
    height: 100%;
  }
}