@use "sass:selector";

.ant-table-content {
  table {
    table-layout: fixed !important;
  }
}
.disorder-form-show {
  form {
    width: 1000px;
  }
}
// .hidden > .ant-form-item-label {
//   display: none;
// }

// // .hiddentitle > .ant-form-item-label {
// //   display: none;
// // }

// //class="ant-col ant-form-item-label"

//  .field-object > .ant-form-item-label {
//   display: none;
//  }

// .hidden > .ant-form-item-label {
//   display: none;
// }

// #app-layout
//   > section
//   > main
//   > form
//   > div
//   > div
//   > div:nth-child(8)
//   > div
//   > div.ant-col.ant-form-item-control-wrapper
//   > div
//   > span
//   > legend {
//   display: none;
// }

// #app-layout
//   > section
//   > main
//   > form
//   > div
//   > div
//   > div:nth-child(9)
//   > div
//   > div.ant-col.ant-form-item-control-wrapper
//   > div
//   > span
//   > legend {
//   display: none;
// }

// #app-layout
//   > section
//   > main
//   > form
//   > div
//   > div
//   > div:nth-child(7)
//   > div
//   > div.ant-col.ant-form-item-control-wrapper
//   > div
//   > span
//   > legend {
//   display: none;
// }

// #app-layout > section > main > form > div > div > div:nth-child(8) > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(12) > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend {
//   display: none;
// }

// //history title

// // #app-layout > section > main > form > div > div > div:nth-child(9) > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(2) > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div {
// //   display: none;
// // }
// // #app-layout > section > main > form > div > div > div:nth-child(9) > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(2) > div > div.ant-col.ant-form-item-label > label {
// // display: none;
// // }

// // #app-layout > section > main > form > div > div > div:nth-child(9) > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(25) > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend {
// //   display: none;
// // }

// //history
// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(2) > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div > div {
//     display: none;
// }

// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(2) > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend {
//   color: black;
//   border-left: 6px solid black;
//   background-color: lightgrey;
//   text-align: center;
// }

// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(8) > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend{
//   display: none;
// }

// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(25) > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend {
//   display: none;
// }
// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(28) > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend {
//   font-weight: bold;;
// }

// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(27) > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend {
//   display: none;
// }

// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(28) > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div {
//   display: none;
// }

// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(36) > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend {
//   display: none;
// }

// //general examination
// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(40) > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend {
//   color: black;
//   border-left: 6px solid black;
//   background-color: lightgrey;
//   text-align: center;
// }

// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(42) > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend {
// font-weight: bold;;
// }

// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(43) > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend {
//   color: black;
//   border-left: 6px solid black;
//   background-color: lightgrey;
//   text-align: center;
// }

// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(43) > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div {
//   display: none;
// }

// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(53) > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend {
//   color: black;
//   border-left: 6px solid black;
//   background-color: lightgrey;
//   text-align: center;
// }
// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(53) > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div {
//   display: none;
// }

// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(61) > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend {
//   color: black;
//   border-left: 6px solid black;
//   background-color: lightgrey;
//   text-align: center;
// }

// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(61) > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div {
//   display: none;
// }

// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(65) > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend {
//   display: none;
// }

// //Comorbid
// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(68) > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend {
//   color: black;
//   border-left: 6px solid black;
//   background-color: lightgrey;
//   text-align: center;
// }

// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(68) > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div {
//   display: none;
// }

// //font size
// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(44) > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend {
//   font-weight: bold;
// }

// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(46) > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend {
//     font-weight: bold;
// }

// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(47) > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend {
//   font-weight: bold;
// }

// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(48) > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend {
//   font-weight: bold;

// }

// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(54) > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend {
//   font-weight: bold;
// }

// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(55) > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend {
//   font-weight: bold;
// }
// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(56) > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend {
//   display: none;
// }

// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(58) > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend {
//   font-weight: bold;
// }

// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > div > div:nth-child(69) > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend {
//   font-weight: bold;
// }

// #app-layout > section > main > form > div > div > div > div > div.ant-col.ant-form-item-control-wrapper > div > span > legend {
// font-weight: bolder;
// }

.ant-tabs {
  width: 100%;
}

.patient-disorder-row-container {
  display: block;
  width: 100%;
}

.view-uploads-modal {
  .ant-modal-body {
    background-color: #fafafb !important;
  }
}

.custom-list-item {
  border: none !important;
  background-color: white !important;
  padding: 15px 20px !important;
  margin: 10px !important;
  border-radius: 10px !important;
  box-sizing: border-box !important;
  &:hover {
    background-color: #eaf8fe !important;
  }
}
