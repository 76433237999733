#app-layout {
  // height: 100vh;
}
#app-layout .trigger {
  height: 64px;
  padding: 22px 24px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
  // @media (max-width: 767px){
  //   display: none;
  // }
}

#app-layout .trigger:hover {
  color: #1890ff;
}

#app-layout .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

#app-layout .mobile-only{
  @media (min-width: 768px){
    display: none;
  }
}

#app-layout .desktop-only {
  @media (max-width: 767px){
    display: none;
  }
}